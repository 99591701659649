<template>
  <div class="dashboard-wrap">
    <div class="list-head">
      <div class="total-wrap"></div>
      <div class="btn" @click="handleMode">文献列表</div>
    </div>
    <div class="content-wrap">
      <ChardCard
        label="地图"
        :isExport="false"
        :count="mapChart.count"
        :loading="mapChart.loading"
        file-name="地图"
        cardId="map"
      >
        <template #right>
          <RadioGroup v-model="mapType" type="button" @on-change="mapChange">
            <Radio label="continent">按大洲</Radio>
            <Radio label="country">按国家</Radio>
            <Radio label="province">按省份</Radio>
          </RadioGroup>
        </template>
        <Map :chart-data="mapChart.data" :map="mapType" @chartClick="handleMapClick"></Map>
      </ChardCard>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            show-count
            :isExport="false"
            label="发表趋势"
            cardId="trend"
            :count="publishChart.count"
            :loading="publishChart.loading"
            file-name="发表趋势"
          >
            <template v-if="publishChart.totalData.length > 5" #right>
              <Page
                @on-change="handlePageChange($event, 'publishChart')"
                :current="publishChart.page"
                :total="publishChart.totalData.length"
                :page-size="5"
                simple
              />
            </template>
            <Histogram
              :size="20"
              :chartData="publishChart.data"
              @histogram="item => handleChartClick(item, '发表趋势')"
              chartID="publishChart"
            ></Histogram>
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="生物标志物"
            :isExport="false"
            cardId="biomarket"
            :count="biomarkerChart.count"
            :loading="biomarkerChart.loading"
            file-name="生物标志物"
          >
            <!-- <template v-if="biomarkerChart.totalData.length > 5" #right>
              <Page
                @on-change="handlePageChange($event, 'biomarkerChart')"
                :current="biomarkerChart.page"
                :total="biomarkerChart.totalData.length"
                :page-size="5"
                simple
              />
            </template> -->
            <Pie
              :innerRadius="0.7"
              @pie="item => handleChartClick(item, '生物标志物')"
              :chartData="biomarkerChart.data"
              chartID="biomarkerChart"
            ></Pie>
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="通讯作者排名"
            :isExport="false"
            cardId="author"
            :count="authorChart.count"
            :loading="authorChart.loading"
            file-name="通讯作者排名"
            show-count
            :isTable="true"
          >
            <template v-if="authorChart.totalData.length > 5" #right>
              <Page
                @on-change="handlePageChange($event, 'authorChart', 10)"
                :current="authorChart.page"
                :total="authorChart.totalData.length"
                simple
              />
            </template>
            <Table :columns="authorChart.columns" @rowClick="(params) => { handleRowClick(params, '通讯作者排名') }" :data="authorChart.data"></Table>
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="机构排名"
            :isExport="false"
            cardId="institution"
            :count="institutionChart.count"
            :loading="institutionChart.loading"
            file-name="机构排行"
            show-count
            :isTable="true"
          >
            <template v-if="institutionChart.totalData.length > 5" #right>
              <Page
                @on-change="handlePageChange($event, 'institutionChart', 10)"
                :current="institutionChart.page"
                :total="institutionChart.totalData.length"
                simple
              />
            </template>
            <Table :columns="institutionChart.columns" @rowClick="(params) => { handleRowClick(params, '机构排名') }" :data="institutionChart.data"></Table>
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="仪器-厂家"
            :isExport="false"
            cardId="manufacturer"
            :count="instrumentChart.count"
            :loading="instrumentChart.loading"
            file-name="仪器-厂家"
          >
            <Pie
              :innerRadius="0.7"
              :count="instrumentChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="instrumentChart.data"
              @pie="item => handleChartClick(item, '仪器-厂家')"
              chartID="instrumentChart"
            ></Pie>
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="试剂品牌"
            :isExport="false"
            cardId="brand"
            :count="reagentChart.count"
            :loading="reagentChart.loading"
            file-name="试剂品牌"
          >
            <Pie
              :innerRadius="0.7"
              :count="reagentChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="reagentChart.data"
              @pie="item => handleChartClick(item, '试剂品牌')"
              chartID="reagentChart"
            ></Pie>
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="相关药物"
            :isExport="false"
            cardId="drug"
            :count="treatmentChart.count"
            :loading="treatmentChart.loading"
            file-name="相关药物"
          >
            <Pie
              :innerRadius="0.7"
              :count="treatmentChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="treatmentChart.data"
              @pie="item => handleChartClick(item, '相关药物')"
              chartID="treatmentChart"
            ></Pie>
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="相关疾病"
            :isExport="false"
            cardId="disease"
            :count="diseaseChart.count"
            :loading="diseaseChart.loading"
            file-name="相关疾病"
          >
            <Pie
              :innerRadius="0.7"
              :count="diseaseChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="diseaseChart.data"
              @pie="item => handleChartClick(item, '相关疾病')"
              chartID="diseaseChart"
            ></Pie>
          </ChardCard>
        </Col>
      </Row>
      <Row :gutter="24">
        <Col span="12">
          <ChardCard
            label="医学方向"
            :isExport="false"
            cardId="medical"
            :count="medicalChart.count"
            :loading="medicalChart.loading"
            file-name="医学方向"
          >
            <Pie
              :innerRadius="0.7"
              :count="medicalChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="medicalChart.data"
              @pie="item => handleChartClick(item, '医学方向')"
              chartID="medicalChart"
            ></Pie>
          </ChardCard>
        </Col>
        <Col span="12">
          <ChardCard
            label="研究人群"
            :isExport="false"
            cardId="population"
            :count="populationChart.count"
            :loading="populationChart.loading"
            file-name="研究人群"
          >
            <Pie
              :innerRadius="0.7"
              :count="populationChart.count"
              subTitle="共计"
              :lengendLength="5"
              :chartData="populationChart.data"
              @pie="item => handleChartClick(item, '研究人群')"
              chartID="populationChart"
            ></Pie>
          </ChardCard>
        </Col>
      </Row>
    </div>
  </div>
</template>

<script>
import { Row, Col, RadioGroup, Radio, Page } from 'view-design'
import ChardCard from '@/components/ChardCard'
import { Histogram } from '@/components/Charts'
import Map from '@/components/echarts/map'
import Table from '@/components/Table'
import { sortRender } from '@/views/literature/components/Dashboard/config'
import Pie from '@/components/echarts/pie'
import {
  getZhiKuDashboardAuthor,
  getZhiKuDashboardBiomarker,
  getZhiKuDashboardContinent,
  getZhiKuDashboardCountry,
  getZhiKuDashboardDiseases,
  getZhiKuDashboardInstitution,
  getZhiKuDashboardInstrument,
  getZhiKuDashboardMedicals,
  getZhiKuDashboardPopulations,
  getZhiKuDashboardProvince,
  getZhiKuDashboardPublish,
  getZhiKuDashboardReagent,
  getZhiKuDashboardTreatments
} from '@/api/zhiku'
import { debounce } from '@/utils/tools'
import Zh_Country from '@/components/echarts/map/country_en.json'

export default {
  components: {
    ChardCard,
    Map,
    Row,
    Col,
    Histogram,
    Table,
    RadioGroup,
    Radio,
    Pie,
    Page
  },
  props: {
    disease: {
      type: [Array, null],
      default: null
    },
    biomarkers: {
      type: [Array, null],
      default: null
    },
    drug: {
      type: [Array, null],
      default: null
    },
    medical: {
      type: [Array, null],
      default: null
    },
    research: {
      type: [Array, null],
      default: null
    },
    reagent: {
      type: [Array, null],
      default: null
    },
    instrument: {
      type: [Array, null],
      default: null
    },
    language: {
      type: [Array, null],
      default: null
    },
    literatureType: {
      type: [Array, null],
      default: null
    },
    startYearValue: {
      type: [String, null],
      default: null
    },
    endYearValue: {
      type: [String, null],
      default: null
    },
    impactFactorValue: {
      type: [Array, null],
      default: null
    }
  },
  watch: {
    disease: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    biomarkers: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    drug: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    medical: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    research: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    reagent: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    instrument: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    language: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    literatureType: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    startYearValue: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    endYearValue: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    impactFactorValue: {
      handler: debounce(function() {
        this.fetchData()
      }, 500),
      deep: true
    }
  },
  data() {
    return {
      mapType: 'country',
      mapChart: {
        data: [],
        count: 0,
        map: {},
        loading: false
      },
      publishChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      biomarkerChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      authorChart: {
        columns: [
          {
            title: '排名',
            key: 'index',
            width: 120,
            render: (h, params) => {
              return sortRender(h, params, this.authorChart.page)
            }
          },
          {
            title: '作者姓名',
            key: 'name',
            align: 'center'
          },
          {
            title: '发表数',
            key: 'value',
            align: 'center'
          }
        ],
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      institutionChart: {
        columns: [
          {
            title: '排名',
            key: 'index',
            width: 80,
            render: (h, params) => {
              return sortRender(h, params, this.institutionChart.page)
            }
          },
          {
            title: '机构名称',
            key: 'name'
          },
          {
            title: '发表数',
            key: 'value',
            align: 'center',
            width: 80
          }
        ],
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      instrumentChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      reagentChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      treatmentChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      diseaseChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      medicalChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      },
      populationChart: {
        data: [],
        count: 0,
        loading: false,
        page: 1,
        totalData: []
      }
    }
  },
  mounted() {
    this.fetchData()
  },
  methods: {
    handleMode() {
      this.$emit('on-mode', 'list')
    },
    handleMapClick(item) {
      const nameMap = {
        continent: '大洲地图',
        country: '世界地图',
        province: '省份地图'
      }
      this.$router.push(
        `/zhiku/dashboards?keyword=${item.key}&value=${Zh_Country[item.name] || item.name}&name=${nameMap[this.mapType]}&biomarkerId=${this.$route.query.id}`
      )
    },
    handleRowClick(item, name) {
      console.log(item)
      this.$router.push(
        `/zhiku/dashboards?keyword=${item.key}&value=${item.name}&name=${name}&biomarkerId=${this.$route.query.id}`
      )
    },
    handleChartClick(item, name) {
      this.$router.push(
        `/zhiku/dashboards?keyword=${item.key}&value=${item.name}&name=${name}&biomarkerId=${this.$route.query.id}`
      )
    },
    pageDataSlice(arr, page, pageSize = 5) {
      const copyArr = [...arr]
      const len = arr.length
      if (len <= pageSize) {
        return arr
      }
      return copyArr.slice((page - 1) * pageSize, page * pageSize)
    },
    handlePageChange(page, key, pageSize = 5) {
      if (!this[key]) return
      this[key].page = page
      this[key].data = this.pageDataSlice(this[key].totalData, page, pageSize)
    },
    fetchData() {
      this.fetchMap()
      this.biomarkerChart.loading = true
      this.authorChart.loading = true
      this.institutionChart.loading = true
      this.publishChart.loading = true
      getZhiKuDashboardPublish({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.publishChart.totalData = res.data.data.filter(item => item.value).reverse()
          this.publishChart.data = this.pageDataSlice(this.publishChart.totalData, this.publishChart.page)
          this.publishChart.count = res.data.all_count
        }
        this.publishChart.loading = false
      })
      getZhiKuDashboardBiomarker({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          // this.biomarkerChart.totalData = res.data.data
          // this.biomarkerChart.data = this.pageDataSlice(this.biomarkerChart.totalData, this.biomarkerChart.page)
          this.biomarkerChart.data = res.data.data
          this.biomarkerChart.count = res.data.all_count
        }
        this.biomarkerChart.loading = false
      })
      getZhiKuDashboardAuthor({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.authorChart.totalData = res.data.data.map((item, index) => ({
            ...item,
            index: index + 1
          }))
          this.authorChart.data = this.pageDataSlice(this.authorChart.totalData, this.authorChart.page, 10)
          this.authorChart.count = res.data.all_count
        }
        this.authorChart.loading = false
      })
      getZhiKuDashboardInstitution({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.institutionChart.totalData = res.data.data.map((item, index) => ({
            ...item,
            index: index + 1
          }))
          this.institutionChart.data = this.pageDataSlice(
            this.institutionChart.totalData,
            this.institutionChart.page,
            10
          )
          this.institutionChart.count = res.data.all_count
        }
        this.institutionChart.loading = false
      })
      this.instrumentChart.loading = true
      getZhiKuDashboardInstrument({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.instrumentChart.totalData = res.data.data
          this.instrumentChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.instrumentChart.count = res.data.all_count
        }
        this.instrumentChart.loading = false
      })
      this.reagentChart.loading = true
      getZhiKuDashboardReagent({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.reagentChart.totalData = res.data.data
          this.reagentChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.reagentChart.count = res.data.all_count
        }
        this.reagentChart.loading = false
      })
      this.treatmentChart.loading = true
      getZhiKuDashboardTreatments({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.treatmentChart.totalData = res.data.data
          this.treatmentChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.treatmentChart.count = res.data.all_count
        }
        this.treatmentChart.loading = false
      })
      this.diseaseChart.loading = true
      getZhiKuDashboardDiseases({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.diseaseChart.totalData = res.data.data
          this.diseaseChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.diseaseChart.count = res.data.all_count
        }
        this.diseaseChart.loading = false
      })
      this.medicalChart.loading = true
      getZhiKuDashboardMedicals({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.medicalChart.totalData = res.data.data
          this.medicalChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.medicalChart.count = res.data.all_count
        }
        this.medicalChart.loading = false
      })
      this.populationChart.loading = true
      getZhiKuDashboardPopulations({
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.populationChart.totalData = res.data.data
          this.populationChart.data = res.data.data.map(item => ({
            ...item,
            num: parseInt(res.data.all_count * (item.value / 100))
          }))
          this.populationChart.count = res.data.all_count
        }
        this.populationChart.loading = false
      })
    },
    mapChange() {
      this.fetchMap()
    },
    fetchMap() {
      this.mapChart.loading = true
      if (this.mapType === 'continent') {
        getZhiKuDashboardContinent({
          biomarker__id__in: this.$route.query.id,
          diseases__id__in: this.disease ? this.disease.join(',') : '',
          biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
          treatments__id__in: this.drug ? this.drug.join(',') : '',
          medicals__id__in: this.medical ? this.medical.join(',') : '',
          populations__id__in: this.research ? this.research.join(',') : '',
          biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
          biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
          language: this.language.join(''),
          article_type: this.literatureType.join(''),
          publish_year__gte: this.startYearValue,
          publish_year__lte: this.endYearValue,
          impact_factor__gte: this.impactFactorValue[0],
          impact_factor__lte: this.impactFactorValue[1]
        }).then(res => {
          if (res.code === 200) {
            this.mapChart.data = res.data.data
            this.mapChart.count = res.data.all_count
          }
          this.mapChart.loading = false
        })
      } else if (this.mapType === 'country') {
        getZhiKuDashboardCountry({
          biomarker__id__in: this.$route.query.id,
          diseases__id__in: this.disease ? this.disease.join(',') : '',
          biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
          treatments__id__in: this.drug ? this.drug.join(',') : '',
          medicals__id__in: this.medical ? this.medical.join(',') : '',
          populations__id__in: this.research ? this.research.join(',') : '',
          biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
          biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
          language: this.language.join(''),
          article_type: this.literatureType.join(''),
          publish_year__gte: this.startYearValue,
          publish_year__lte: this.endYearValue,
          impact_factor__gte: this.impactFactorValue[0],
          impact_factor__lte: this.impactFactorValue[1]
        }).then(res => {
          if (res.code === 200) {
            this.mapChart.data = res.data.data
            this.mapChart.count = res.data.all_count
          }
          this.mapChart.loading = false
        })
      } else {
        getZhiKuDashboardProvince({
          biomarker__id__in: this.$route.query.id,
          diseases__id__in: this.disease ? this.disease.join(',') : '',
          biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
          treatments__id__in: this.drug ? this.drug.join(',') : '',
          medicals__id__in: this.medical ? this.medical.join(',') : '',
          populations__id__in: this.research ? this.research.join(',') : '',
          biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
          biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
          language: this.language.join(''),
          article_type: this.literatureType.join(''),
          publish_year__gte: this.startYearValue,
          publish_year__lte: this.endYearValue,
          impact_factor__gte: this.impactFactorValue[0],
          impact_factor__lte: this.impactFactorValue[1]
        }).then(res => {
          if (res.code === 200) {
            this.mapChart.data = res.data.data
            this.mapChart.count = res.data.all_count
          }
          this.mapChart.loading = false
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.dashboard-wrap {
  .list-head {
    height: 50px;
    padding: 0 40px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: #fff;
    border-radius: 12px 12px 0 0;
    border-bottom: 1px solid #ebeef5;

    .btn {
      padding: 0 16px;
      height: 32px;
      line-height: 30px;
      user-select: none;
      font-size: 16px;
      box-sizing: border-box;
      color: #007fff;
      cursor: pointer;
      border-radius: 6px;
      border: 1px solid #007fff;
    }
  }

  .content-wrap {
    padding: 32px 40px;
    background-color: #fff;
    border-radius: 0 0 12px 12px;
  }
}
</style>
