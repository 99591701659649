<template>
  <div class="chart-wrap" ref="chartWrap"></div>
</template>

<script>
import * as echarts from 'echarts'
import provinceMap from './province.json'
import continentMap from './continent.json'
import countryMap from './country.json'
import Zh_CnConuntry from '@/views/system/zhiku/conuntry.json'

export default {
  data() {
    return {
      chart: null,
      provinceMap: provinceMap,
      continentMap: continentMap,
      countryMap: countryMap
    }
  },
  props: {
    map: {
      type: String,
      default: 'country'
    },
    chartData: {
      type: Array,
      default: () => []
    }
  },
  mounted() {
    echarts.registerMap('province', this.provinceMap)
    echarts.registerMap('country', this.countryMap)
    echarts.registerMap('continent', this.continentMap)
    this.chart = echarts.init(this.$refs.chartWrap)
    // chartData.nodes.forEach(function(node) {
    //   node.symbolSize = 5
    // })
    this.chart.on('click', 'series.map', e => {
      if (e.data) {
        this.$emit('chartClick', e.data)
      }
    })
    this.setChart()
  },
  watch: {
    chartData: {
      handler() {
        this.setChart()
      },
      deep: true
    }
  },
  methods: {
    setChart() {
      if (this.chart) {
        const mapData = this.chartData.map(item => {
          return {
            ...item,
            name: Zh_CnConuntry[item.name] || item.name,
            itemStyle: {
              color: '#2d8cf0',
              areaColor: '#2d8cf0'
            }
          }
        })
        console.log(mapData, 'dd')
        this.chart.setOption({
          tooltip: {
            show: true,
            trigger: 'item',
            // backgroundColor: 'rgba(3, 30, 47, 0.7)',
            // borderColor: 'rgba(64, 255, 210, 0.2)',
            formatter: function(e) {
              return `${Zh_CnConuntry[e.name] || e.name} ${e.value || '-'}`
            }
          },
          // legend: [
          //   {
          //     // selectedMode: 'single',
          //     data: chartData.categories.map(function(a) {
          //       return a.name
          //     })
          //   }
          // ],
          series: [
            {
              type: 'map',
              mapType: this.map,
              data: mapData,
              zoom: 1.1,
              coordinateSystem: 'geo',
              symbol: 'pin',
              selectedMode: 'multiple',
              label: {
                normal: {
                  show: false
                },
                emphasis: {
                  show: false
                }
              },
              emphasis: {
                itemStyle: {
                  color: '#2d8cf0',
                  areaColor: '#2d8cf0'
                }
              },
              select: {
                itemStyle: {
                  color: '#2d8cf0',
                  areaColor: '#2d8cf0'
                }
              }
            }
          ]
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  width: 100%;
  //height: 100%;
  height: 400px;
}
</style>
