<template>
  <div class="chart-wrap" ref="chartWrap" :style="{ '--chart-height': chartHeight + 'px' }"></div>
</template>

<script>
import * as echarts from 'echarts'

export default {
  props: {
    chartData: {
      type: Array,
      default: () => []
    },
    chartHeight: {
      type: Number,
      default: 400
    },
    showUnit: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chartWrap)
    this.chart.on('click', 'series.pie', e => {
      this.$emit('pie', e.data)
    })
    this.setChart()
  },
  watch: {
    chartData: {
      handler() {
        this.setChart()
      },
      deep: true
    }
  },
  methods: {
    setChart() {
      const option = {
        tooltip: {
          trigger: 'item',
          formatter: (params) => {
            return `${params.name}: ${params.value} (${params.percent}%)`
          }
        },
        legend: {
          icon: 'circle',
          orient: 'horizontal',
          left: 'center',
          top: '20px',
          data: this.chartData.slice(0, 5).map(item => item.name)
        },
        series: [
          {
            name: 'pie',
            type: 'pie',
            radius: '50%',
            data: this.chartData,
            emphasis: {
              itemStyle: {
                shadowBlur: 10,
                shadowOffsetX: 0,
                shadowColor: 'rgba(0, 0, 0, 0.5)'
              }
            }
          }
        ]
      }
      this.chart.setOption(option)
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  width: 100%;
  height: var(--chart-height);
}
</style>
