<template>
  <div class="chart-wrap" ref="chartWrap"></div>
</template>

<script>
import * as echarts from 'echarts'
import * as d3 from 'd3'
import { cloneDeep } from '@/utils/tools'

export default {
  data() {
    return {
      chart: null,
      selected: []
    }
  },
  model: {
    prop: 'isClear',
    event: 'change'
  },
  props: {
    chartData: {
      type: Object,
      default: () => ({
        nodes: [],
        links: [],
        categories: []
      })
    },
    isClear: {
      type: Boolean,
      default: false
    }
  },
  mounted() {
    this.chart = echarts.init(this.$refs.chartWrap)
    // chartData.nodes.forEach(function(node) {
    //   node.symbolSize = 5
    // })
    this.chart.on('click', 'series.graph', e => {
      this.$emit('nodeClick', e.data)
      this.$emit('change', false)
    })
    this.chart.on('selectchanged', 'series.graph', e => {
      this.selected = e.selected
    })
    this.setChart()
  },
  watch: {
    chartData: {
      handler() {
        this.setChart()
      },
      deep: true
    },
    isClear: {
      handler(val) {
        console.log(val, 'isClear')
        if (val) {
          this.chart.dispatchAction({
            type: 'unselect',
            seriesIndex: 0,
            dataIndex: this.selected.find(item => item.dataType === 'node').dataIndex
          })
        }
      },
      deep: true
    }
  },
  methods: {
    createCustomLayout(nodes) {
      const simulation = d3.forceSimulation().nodes(nodes) // nodes是你的节点数组
      simulation.force('category').initialize(nodes, function(d) {
        return d.category ? { x: categoryX(d.category), y: categoryY(d.category) } : { x: 0, y: 0 }
      })
      function categoryX(category) {
        switch (category) {
          case 0:
            return -300
          case 1:
            return -100
          case 2:
            return 100
          case 3:
            return 300
          case 4:
            return -300
          case 5:
            return -100
          case 6:
            return 100
          case 7:
            return 300
          case 8:
            return -300
          default:
            return 0
        }
      }
      function categoryY(category) {
        switch (category) {
          case 0:
            return -300
          case 1:
            return -300
          case 2:
            return -300
          case 3:
            return -300
          case 4:
            return 0
          case 5:
            return 0
          case 6:
            return 0
          case 7:
            return 0
          case 8:
            return 300
          default:
            return 0
        }
      }
      // 运行模拟器
      simulation.force('charge', d3.forceManyBody().strength(-20)).force(
        'collide',
        d3
          .forceCollide()
          .radius(60)
          .iterations(2)
      )
    },
    setChart() {
      if (this.chart) {
        const nodes = cloneDeep(this.chartData.nodes)
        const colorMap = new Map([
          ['主题生物标志物', { 0: '#1a85c6', 1: '#ADCE48', 2: '#1a85c6', 3: '#ADCE48' }],
          ['相关疾病', { 0: '#db71a1', 1: '#db71a1', 2: '#c81769', 3: '#db71a1' }],
          ['医学方向', { 0: '#dbb177', 1: '#dbb177', 2: '#c88417', 3: '#dbb177' }],
          ['研究人群', { 0: '#8f6681', 1: '#8f6681', 2: '#4b0331', 3: '#8f6681' }],
          ['相关生物标志物', { 0: '#7da593', 1: '#7da593', 2: '#2b7051', 3: '#7da593' }],
          ['试剂品牌', { 0: '#7e74bd', 1: '#7e74bd', 2: '#372998', 3: '#7e74bd' }],
          ['仪器厂家', { 0: '#87d1a1', 1: '#87d1a1', 2: '#3cb868', 3: '#87d1a1' }]
        ])
        // const hexToRgba = (hex, opacity) => {
        //   hex = hex.replace('#', '')
        //   var r = parseInt(hex.substring(0, 2), 16)
        //   var g = parseInt(hex.substring(2, 4), 16)
        //   var b = parseInt(hex.substring(4, 6), 16)

        //   return 'rgba(' + r + ',' + g + ',' + b + ',' + opacity + ')'
        // }
        const formatText = (text, maxCharactersPerLine, maxLines) => {
          if (!text || typeof text !== 'string' || maxCharactersPerLine <= 0 || maxLines <= 0) {
            return ''
          }

          const lines = []
          let currentLine = ''
          let lineCount = 0

          for (let i = 0; i < text.length; i++) {
            currentLine += text[i]

            if (currentLine.length === maxCharactersPerLine) {
              lines.push(currentLine)
              currentLine = ''
              lineCount++

              if (lineCount === maxLines) {
                break // Stop processing after reaching maxLines
              }
            } else if (currentLine.length > maxCharactersPerLine) {
              lines.push(currentLine.slice(0, -1) + '...')
              currentLine = ''
              lineCount++

              if (lineCount === maxLines) {
                break // Stop processing after reaching maxLines
              }
            }
          }

          if (currentLine.length > 0) {
            lines.push(currentLine)
          }

          if (lineCount >= maxLines && currentLine.length === 0) {
            // If reached maxLines and the last line is empty, remove the last line and add '...'
            lines.pop()
            lines.push(lines[lines.length - 1].slice(0, -3) + '...')
          }

          return lines.join('\n')
        }

        // 写一个判断中英文的函数
        const isChinese = str => {
          return /[\u4e00-\u9fa5]/.test(str)
        }

        if (nodes.length === 0) return
        this.chart.setOption({
          // color: ['#00B4ED', '#36CBCB', '#4DCB73', '#FAD337', '#E15EB6', '#F2637B', '#E15EB6'].reverse(),
          tooltip: {},
          legend: [
            {
              // selectedMode: 'single',
              data: this.chartData.categories.map(function(a) {
                return {
                  name: a.name,
                  icon: 'circle',
                  itemStyle: {
                    color: colorMap.get(a.name)[2]
                  }
                }
              }),
              right: 10,
              bottom: 10,
              orient: 'vertical'
            }
          ],
          series: [
            {
              type: 'graph',
              layout: 'force',
              draggable: true,
              data: nodes.map(item => {
                const level = item.category === '主题生物标志物' ? item.level : item.level + 1
                return {
                  ...item,
                  itemStyle: {
                    color: item.level === 0 ? colorMap.get(item.category)[0] : colorMap.get(item.category)[level]
                  }
                }
              }),
              links: this.chartData.links,
              categories: this.chartData.categories,
              roam: true,
              zoom: 1,
              cursor: 'pointer',
              label: {
                show: true,
                position: 'inside',
                rich: {
                  a: {
                    color: '#fff',
                    fontWeight: 800,
                    verticalAlign: 'middle'
                  },
                  b: {
                    color: '#fff',
                    verticalAlign: 'middle'
                  }
                },
                formatter: function(params) {
                  const level = params.data.category === '主题生物标志物' ? params.data.level : params.data.level + 1
                  const name = params.data.name
                  switch (level) {
                    case 0:
                      return `{a|${isChinese(name) ? formatText(name, 5, 4) : formatText(name, 10, 2)}}`
                    case 1:
                      return `{a|${isChinese(name) ? formatText(name, 4, 3) : formatText(name, 8, 2)}}`
                    case 2:
                      return `{b|${isChinese(name) ? formatText(name, 3, 3) : formatText(name, 8, 3)}}`
                    case 3:
                      return `{b|${isChinese(name) ? formatText(name, 2, 2) : formatText(name, 4, 2)}}`
                  }
                }
              },
              force: {
                repulsion: 200,
                edgeLength: 100,
                friction: 0.1
              },
              select: {
                itemStyle: {
                  color: '#ccc',
                  borderColor: '#ccc'
                }
              },
              selectedMode: 'multiple'
            }
          ]
        })
      }
    }
  }
}
</script>

<style lang="less" scoped>
.chart-wrap {
  width: 100%;
  height: 100%;
}
</style>
