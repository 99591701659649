<template>
  <div class="list-wrap">
    <div class="list-header">
      <div class="left-wrap">
        <Checkbox :indeterminate="indeterminate" :value="checkAll" @click.prevent.native="handleCheckAll">
          <span style="margin-left: 8px;">全选</span>
        </Checkbox>
        <div>
          共<span style="margin: 0 4px;">{{ total }}</span
          >条数据
        </div>
      </div>
      <div class="right-wrap">
        <div class="sort-wrap">
          <div style="color: #8E9CA9;">排序：</div>
          <div class="sort-item" @click="handleSort">
            <span style="color: #494E53;">发表时间</span>
            <i
              class="iconfont iconbianzu22"
              :style="{ color: this.ordering === 'publish_year' ? '#007FFF' : '#656e77' }"
            ></i>
            <i
              class="iconfont iconbianzu21"
              :style="{ marginLeft: '-8px', color: this.ordering === '-publish_year' ? '#007FFF' : '#656e77' }"
            ></i>
          </div>
          <div class="sort-item" style="margin-left: 16px;" @click="handleSort2">
            <span style="color: #494E53;">IF</span>
            <i
              class="iconfont iconbianzu22"
              :style="{ color: this.ordering2 === 'impact_factor' ? '#007FFF' : '#656e77' }"
            ></i>
            <i
              class="iconfont iconbianzu21"
              :style="{ marginLeft: '-8px', color: this.ordering2 === '-impact_factor' ? '#007FFF' : '#656e77' }"
            ></i>
          </div>
          <Divider type="vertical" />
          <Dropdown @on-click="downloadHandle">
            <i class="iconfont iconxiazai1 download" style="cursor: pointer; font-size: 14px; color: #8E9CA9;"></i>
            <DropdownMenu slot="list">
              <DropdownItem name="pdf">PDF下载</DropdownItem>
              <DropdownItem name="excel">EXCEL下载</DropdownItem>
              <DropdownItem name="ppt">PPT下载</DropdownItem>
            </DropdownMenu>
          </Dropdown>
          <div class="btn" @click="handleMode">数据看板</div>
        </div>
      </div>
    </div>
    <div class="list-content-wrap">
      <CheckboxGroup v-model="checkAllGroup" @on-change="checkAllGroupChange">
        <ListItem v-for="item in dataList" :key="item.id" :item="item" />
      </CheckboxGroup>
      <div style="display: flex;justify-content: flex-end;padding: 32px 40px;">
        <Page :total="total" :current="page" show-total @on-change="pageChange" />
      </div>
    </div>
  </div>
</template>

<script>
import { CheckboxGroup, Checkbox, Divider, Page, Dropdown, DropdownMenu, DropdownItem, Message } from 'view-design'
import {
  getZhiKuLiteratureList,
  getZhiKuLiteratureListEXCEL,
  getZhiKuLiteratureListPDF,
  getZhiKuLiteratureListPPT
} from '@/api/zhiku'
import { saveAs } from 'file-saver'
import ListItem from './list-item'
import { debounce } from '@/utils/tools'
export default {
  props: {
    disease: {
      type: [Array, null],
      default: null
    },
    biomarkers: {
      type: [Array, null],
      default: null
    },
    drug: {
      type: [Array, null],
      default: null
    },
    medical: {
      type: [Array, null],
      default: null
    },
    research: {
      type: [Array, null],
      default: null
    },
    reagent: {
      type: [Array, null],
      default: null
    },
    instrument: {
      type: [Array, null],
      default: null
    },
    search: {
      type: [String, null],
      default: null
    },
    chartFilter: {
      type: Object,
      default: () => ({})
    },
    researchType: {
      type: [Array, null],
      default: null
    },
    language: {
      type: [Array, null],
      default: null
    },
    literatureType: {
      type: [Array, null],
      default: null
    },
    startYearValue: {
      type: [String, null],
      default: null
    },
    endYearValue: {
      type: [String, null],
      default: null
    },
    impactFactorValue: {
      type: [Array, null],
      default: null
    }
  },
  components: {
    CheckboxGroup,
    Checkbox,
    Divider,
    Page,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    ListItem
  },
  data() {
    return {
      checkAllGroup: [],
      indeterminate: false,
      checkAll: false,
      dataList: [],
      total: 10,
      page: 1,
      ordering: '',
      ordering2: ''
    }
  },
  mounted() {
    this.fetchData()
  },
  watch: {
    search: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    disease: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    biomarkers: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    drug: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    medical: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    research: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    reagent: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    instrument: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    researchType: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    chartFilter: {
      handler(val) {
        this.fetchData()
      },
      deep: true,
      immediate: true
    },
    language: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    literatureType: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    startYearValue: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    endYearValue: {
      handler() {
        this.fetchData()
      },
      deep: true
    },
    impactFactorValue: {
      handler: debounce(function() {
        this.fetchData()
      }, 500),
      deep: true
    }
  },
  methods: {
    fetchData() {
      const ordering = []
      if (this.ordering !== '') {
        ordering.push(this.ordering)
      }
      if (this.ordering2 !== '') {
        ordering.push(this.ordering2)
      }
      getZhiKuLiteratureList({
        ordering: ordering.join(','),
        page: this.page,
        search: this.search,
        biomarker__id__in: this.$route.query.id,
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        research_type__id__in: this.researchType ? this.researchType.join(',') : '',
        ...this.chartFilter,
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0] || 0,
        impact_factor__lte: this.impactFactorValue[1]
      }).then(res => {
        if (res.code === 200) {
          this.dataList = res.data
          this.total = res.page.count
          if (this.checkAll) {
            this.checkAllGroup = Array.from(new Set(this.checkAllGroup.concat(this.dataList.map(item => item.id))))
          }
        }
      })
    },
    pageChange(page) {
      this.page = page
      this.fetchData()
    },
    handleMode() {
      this.$emit('on-mode', 'dashboard')
    },
    checkAllGroupChange(data) {
      if (data.length === 10) {
        this.indeterminate = false
        this.checkAll = true
      } else if (data.length > 0) {
        this.indeterminate = true
        this.checkAll = false
      } else {
        this.indeterminate = false
        this.checkAll = false
      }
    },
    handleCheckAll() {
      if (this.indeterminate) {
        this.checkAll = false
      } else {
        this.checkAll = !this.checkAll
      }
      this.indeterminate = false

      if (this.checkAll) {
        this.checkAllGroup = this.dataList.map(item => item.id)
      } else {
        this.checkAllGroup = []
      }
    },
    handleSort() {
      if (this.ordering === '') {
        this.ordering = '-publish_year'
      } else if (this.ordering === '-publish_year') {
        this.ordering = 'publish_year'
      } else {
        this.ordering = ''
      }
      this.fetchData()
    },
    handleSort2() {
      if (this.ordering2 === '') {
        this.ordering2 = '-impact_factor'
      } else if (this.ordering2 === '-impact_factor') {
        this.ordering2 = 'impact_factor'
      } else {
        this.ordering2 = ''
      }
      this.fetchData()
    },
    downloadHandle(type) {
      if (this.checkAllGroup.length > 0) {
        console.log(this.checkAllGroup, type)
        if (type === 'pdf') {
          getZhiKuLiteratureListPDF({
            id__in: this.checkAllGroup.join(',')
          }).then(res => {
            saveAs(res, '智库数据.pdf')
          })
        }
        if (type === 'excel') {
          getZhiKuLiteratureListEXCEL({
            id__in: this.checkAllGroup.join(',')
          }).then(res => {
            saveAs(res, '智库数据.xlsx')
          })
        }
        if (type === 'ppt') {
          getZhiKuLiteratureListPPT({
            id__in: this.checkAllGroup.join(',')
          }).then(res => {
            saveAs(res, '智库数据.pptx')
          })
        }
      } else {
        Message.info('请至少选择一篇')
      }
    }
  }
}
</script>

<style lang="less" scoped>
.list-wrap {
  .list-header {
    background: #ffffff;
    border-radius: 12px 12px 0 0;
    padding: 16px 40px;
    font-size: 14px;
    color: #656e77;
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid #e8ecef;

    .left-wrap {
      display: flex;
    }

    .right-wrap {
      display: flex;
      align-items: center;

      .sort-wrap {
        display: flex;
        align-items: center;

        .sort-item {
          display: flex;
          align-items: center;
          cursor: pointer;
          user-select: none;

          & > i {
            font-weight: 500;
            font-size: 15px;
          }
        }
      }

      .btn {
        margin-left: 24px;
        padding: 0 16px;
        height: 32px;
        line-height: 30px;
        font-size: 16px;
        box-sizing: border-box;
        color: #007fff;
        cursor: pointer;
        border-radius: 6px;
        border: 1px solid #007fff;
      }
    }
  }

  .list-content-wrap {
    background-color: #fff;
    border-radius: 0 0 12px 12px;
  }
}
</style>
