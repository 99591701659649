<template>
  <div class="page-wrap">
    <div class="header-wrap" style="margin-bottom: 24px">
      <Breadcrumb style="font-size: 16px;">
        <BreadcrumbItem to="/zhiku">
          <i class="iconfont iconxinxiku" style="margin-right: 10px;"></i>
          <span>智库首页</span>
        </BreadcrumbItem>
        <BreadcrumbItem>
          智库列表
        </BreadcrumbItem>
      </Breadcrumb>
      <div class="header-formulas">
        {{ $route.query.name }}
        <div class="formula-item" v-for="(item, index) in formulas.disease" :key="index + 'd'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.biomarkers" :key="index + 'b'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.drug" :key="index + 'dr'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.medical" :key="index + 'me'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.research" :key="index + 'r'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.reagent" :key="index + 're'">
          <span>-</span>
          {{ item }}
        </div>
        <div class="formula-item" v-for="(item, index) in formulas.instrument" :key="index + 'i'">
          <span>-</span>
          {{ item }}
        </div>
      </div>
      <div style="flex: 1; margin-left: 20px;"></div>
      <Button @click="clearHandle" style="font-size: 16px" type="primary" ghost
        ><i class="iconfont iconshanchu"></i>清空筛选</Button
      >
      <Button style="font-size: 16px; margin-left: 12px" type="primary" ghost @click="subscribeHandle">订阅</Button>
    </div>
    <div class="search-wrap" v-if="mode === 'list'">
      <Input v-model="value" style="margin-right: 16px;width: 60%;" size="large" placeholder="请输入关键词进行搜索" />
      <Button type="primary" size="large" ghost @click="searchHandle">搜索</Button>
    </div>
    <div class="chart-tupu" v-if="mode === 'list'">
      <Spin v-if="chartLoading">
        <Icon type="ios-loading" size="18" class="demo-spin-icon-load"></Icon>
        <div>加载中...</div>
      </Spin>
      <GraphChart v-else v-model="graphClear" :chart-data="chartData" @nodeClick="handleNodeClick" />
      <!-- <TreeChart v-else v-model="graphClear" :chart-data="chartData" @nodeClick="handleNodeClick" /> -->
    </div>
    <Row :gutter="24" style="margin-bottom: 16px;">
      <Col span="6">
        <div class="filter-card">
          <div class="filter-item">
            <div class="title">相关疾病</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="disease"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="diseaseList"
              @input="diseaseChange"
            >
            </treeselect>
          </div>
          <div class="filter-item">
            <div class="title">相关生物标志物</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="biomarkers"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="biomarkersList"
              @input="biomarkersChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">治疗</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="drug"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="drugList"
              @input="drugChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">医学方向</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="medical"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="medicalList"
              @input="medicalChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">研究人群</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="research"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="researchList"
              @input="researchChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">试剂品牌</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="reagent"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="reagentList"
              @input="reagentChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">仪器品牌</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="instrument"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="instrumentList"
              @input="instrumentChange"
            />
          </div>
          <div class="filter-item">
            <div class="title">研究类型</div>
            <treeselect
              placeholder="请选择"
              value-consists-of="ALL"
              v-model="researchType"
              noOptionsText="暂无数据"
              :multiple="true"
              :options="researchTypeList"
              @input="researchTypeChange"
            />
          </div>
        </div>
        <div class="filter-card" style="margin-top: 12px;">
          <div class="filter-item">
            <div class="title">语言类型</div>
            <FilterCom v-model="language" :multiple="false" @change="handleLanguageChange" :list="languageList" />
          </div>
          <div class="filter-item">
            <div class="title">文献类型</div>
            <FilterCom
              v-model="literatureType"
              :multiple="false"
              @change="handleLiteratureTypeChange"
              :list="literatureTypeList"
            />
          </div>
          <div class="filter-item">
            <div class="title">发表年份</div>
            <div style="display: flex; align-items: center;">
              <DatePicker
                type="year"
                :options="disabledDate"
                placeholder="起始年份"
                style="width: 200px"
                @on-change="startYearChange"
              />
              <span style="margin: 0 8px;">一</span>
              <DatePicker
                type="year"
                :options="disabledDate"
                placeholder="结束年份"
                style="width: 200px"
                @on-change="endYearChange"
              />
            </div>
          </div>
          <div class="filter-item">
            <div class="title">影响因子</div>
            <Slider v-model="impactFactorValue" :step="0.1" :max="impactFactorMax" range></Slider>
          </div>
        </div>
      </Col>
      <Col span="18">
        <List
          v-if="mode === 'list'"
          @on-mode="handleMode"
          :disease="disease"
          :biomarkers="biomarkers"
          :drug="drug"
          :medical="medical"
          :research="research"
          :reagent="reagent"
          :search="search"
          :instrument="instrument"
          :chartFilter="chartFilter"
          :researchType="researchType"
          :language="language"
          :literatureType="literatureType"
          :startYearValue="startYearValue"
          :endYearValue="endYearValue"
          :impactFactorValue="impactFactorValue"
        />
        <Dashboard
          v-else
          @on-mode="handleMode"
          :disease="disease"
          :biomarkers="biomarkers"
          :drug="drug"
          :medical="medical"
          :research="research"
          :reagent="reagent"
          :instrument="instrument"
          :researchType="researchType"
          :language="language"
          :literatureType="literatureType"
          :startYearValue="startYearValue"
          :endYearValue="endYearValue"
          :impactFactorValue="impactFactorValue"
        />
      </Col>
    </Row>
  </div>
</template>

<script>
import {
  Input,
  Button,
  Row,
  Col,
  Breadcrumb,
  BreadcrumbItem,
  Message,
  Spin,
  Icon,
  DatePicker,
  Slider
} from 'view-design'
import List from './list'
import Dashboard from './dashboard'
// import TreeChart from '@/components/echarts/tree/'
import GraphChart from '@/components/echarts/graph/'
import FilterCom from '@/views/meeting/filter'
import {
  addZhiKuRetrieval,
  getBiomarkersListArticle,
  getDiseaseListArticle,
  getDrugListArticle,
  getInstrumentListArticle,
  getMedicalListArticle,
  getReagentListArticle,
  getResearchListArticle,
  getZhiKuLabelChart,
  getZhiKuRetrievalDetail,
  getResearchTypeFilter,
  getLiteratureTypeList,
  getPublishYearList,
  getImpactFactorList
} from '@/api/zhiku'
import { deepCopy, debounce } from '@/utils/tools'

export default {
  name: 'ZhiKuList',
  components: {
    Input,
    Button,
    Row,
    Col,
    List,
    Breadcrumb,
    BreadcrumbItem,
    Dashboard,
    Spin,
    Icon,
    // TreeChart,
    GraphChart,
    FilterCom,
    DatePicker,
    Slider
  },
  data() {
    return {
      value: null,
      search: null,
      mode: 'list',
      options: [],
      formulas: {
        disease: [],
        biomarkers: [],
        drug: [],
        medical: [],
        research: [],
        reagent: [],
        instrument: []
      },
      diseaseList: [],
      disease: null,
      biomarkersList: [],
      biomarkers: null,
      drugList: [],
      drug: null,
      medicalList: [],
      medical: null,
      researchList: [],
      research: null,
      reagentList: [],
      reagent: null,
      instrumentList: [],
      instrument: null,
      researchTypeList: [],
      researchType: null,
      filterText: [],
      chartData: {
        categories: [],
        nodes: [],
        links: []
      },
      chartLoading: false,
      chartFilter: {},
      graphClear: false,
      language: [],
      languageList: [
        { name: '中文', id: '中文' },
        { name: '外文', id: '外文' }
      ],
      literatureType: [],
      literatureTypeList: [],
      maxYear: null,
      minYear: null,
      startYearValue: '',
      endYearValue: '',
      disabledDate: {},
      impactFactor: [],
      impactFactorValue: []
    }
  },
  mounted() {
    this.init()
    if (this.$route.query.filter) {
      getZhiKuRetrievalDetail(this.$route.query.filter).then(res => {
        if (res.code === 200) {
          this.formulas = res.data.search_content.formulas
          this.disease = res.data.search_content.filter.disease
          this.biomarkers = res.data.search_content.filter.biomarkers
          this.drug = res.data.search_content.filter.drug
          this.instrument = res.data.search_content.filter.instrument
          this.medical = res.data.search_content.filter.medical
          this.reagent = res.data.search_content.filter.reagent
          this.research = res.data.search_content.filter.research
        }
      })
    } else {
      this.fetchData()
    }
  },
  watch: {
    search: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    disease: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    biomarkers: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    drug: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    medical: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    research: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    reagent: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    instrument: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    researchType: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    language: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    literatureType: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    startYearValue: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    endYearValue: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    },
    impactFactorValue: {
      handler: debounce(function() {
        this.fetchData()
        this.init()
      }, 500),
      deep: true
    },
    chartFilter: {
      handler() {
        this.fetchData()
        this.init()
      },
      deep: true
    }
  },
  computed: {
    filterParams() {
      return {
        biomarker__id__in: this.$route.query.id,
        search: this.search || '',
        diseases__id__in: this.disease ? this.disease.join(',') : '',
        biomarker_tags__biomarker__id__in: this.biomarkers ? this.biomarkers.join(',') : '',
        treatments__id__in: this.drug ? this.drug.join(',') : '',
        medicals__id__in: this.medical ? this.medical.join(',') : '',
        populations__id__in: this.research ? this.research.join(',') : '',
        biomarker_tags__reagent__id__in: this.reagent ? this.reagent.join(',') : '',
        biomarker_tags__instrument__id__in: this.instrument ? this.instrument.join(',') : '',
        research_type__id__in: this.researchType ? this.researchType.join(',') : '',
        language: this.language.join(''),
        article_type: this.literatureType.join(''),
        publish_year__gte: this.startYearValue,
        publish_year__lte: this.endYearValue,
        impact_factor__gte: this.impactFactorValue[0],
        impact_factor__lte: this.impactFactorValue[1]
      }
    },
    impactFactorMax() {
      if (!isNaN(this.impactFactor[1])) {
        return Math.ceil(this.impactFactor[1])
      }
      return 100
    }
  },
  created() {
    getLiteratureTypeList().then(res => {
      const map = {
        指南共识: '1',
        '综述/Meta分析': '2',
        研究: '3',
        其他: '4'
      }
      if (res.code === 200) {
        console.log(res.data, 'res.data')
        this.literatureTypeList = res.data.map(item => {
          return {
            id: map[item],
            name: item
          }
        })
      }
    })
    getPublishYearList().then(res => {
      if (res.code === 200) {
        this.maxYear = new Date(`${res.data.max_value}-01-01`).valueOf()
        this.minYear = new Date(`${res.data.min_value}-01-01`).valueOf()
        this.disabledDate = {
          disabledDate: time => {
            return time.getTime() > this.maxYear || time.getTime() < this.minYear
          }
        }
      }
    })
    getImpactFactorList({
      biomarker__id__in: this.$route.query.id
    }).then(res => {
      if (res.code === 200) {
        this.impactFactorValue = [res.data.min_value, res.data.max_value]
        this.impactFactor = [res.data.min_value, res.data.max_value]
      }
    })
  },
  methods: {
    // 深度遍历树
    deepTraversal(trees, ids) {
      const nodes = []
      if (ids && ids.length > 0) {
        const stack = deepCopy(trees)
        while (stack.length !== 0) {
          const item = stack.pop()
          if (ids.indexOf(item.id) > -1) {
            nodes.push(item.label)
          }
          if (item.children) {
            const childrens = item.children
            for (let i = childrens.length - 1; i >= 0; i--) {
              stack.push(childrens[i])
            }
          }
        }
      }
      return nodes
    },
    startYearChange(e) {
      this.startYearValue = e
    },
    endYearChange(e) {
      this.endYearValue = e
    },
    diseaseChange(e) {
      console.log(e, 'e')
      this.formulas.disease = this.deepTraversal(this.diseaseList, e)
    },
    biomarkersChange(e) {
      this.formulas.biomarkers = this.deepTraversal(this.biomarkersList, e)
    },
    drugChange(e) {
      this.formulas.drug = this.deepTraversal(this.drugList, e)
    },
    medicalChange(e) {
      this.formulas.medical = this.deepTraversal(this.medicalList, e)
    },
    researchChange(e) {
      this.formulas.research = this.deepTraversal(this.researchList, e)
    },
    reagentChange(e) {
      this.formulas.reagent = this.deepTraversal(this.reagentList, e)
    },
    instrumentChange(e) {
      this.formulas.instrument = this.deepTraversal(this.instrumentList, e)
    },
    researchTypeChange(e) {
      this.formulas.researchType = this.deepTraversal(this.researchTypeList, e)
    },
    clearHandle() {
      this.graphClear = true
      this.disease = null
      this.drug = null
      this.biomarkers = null
      this.medical = null
      this.research = null
      this.reagent = null
      this.instrument = null
      this.chartFilter = {}
      this.language = []
      this.literatureType = []
      this.startYearValue = ''
      this.endYearValue = ''
      this.impactFactorValue = this.impactFactor
    },
    createNewTrees(arr) {
      if (arr.length) {
        const newArr = []
        arr.map(item => {
          if (item.children && item.children.length > 0) {
            newArr.push({
              ...item,
              label: item.name,
              children: this.createNewTrees(item.children)
            })
          } else {
            newArr.push({
              id: item.id,
              label: item.name
            })
          }
        })
        return newArr
      } else {
        return []
      }
    },
    init() {
      this.fetchDisease()
      this.fetchBiomarkers()
      this.fetchDrug()
      this.fetchMedical()
      this.fetchResearch()
      this.fetchReagent()
      this.fetchInstrument()
      getResearchTypeFilter({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.researchTypeList = this.createNewTrees(res.data)
        }
      })
    },
    unitary(xmax, xmin, ymax, ymin, num) {
      if (xmax === xmin) {
        return ymax
      } else {
        return ymin + ((ymax - ymin) / (xmax - xmin)) * (num - xmin)
      }
    },
    searchHandle() {
      this.search = this.value
      this.fetchData()
    },
    fetchDisease() {
      getDiseaseListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.diseaseList = this.createNewTrees(res.data)
        }
      })
    },
    fetchBiomarkers() {
      getBiomarkersListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.biomarkersList = this.createNewTrees(res.data)
        }
      })
    },
    fetchDrug() {
      getDrugListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.drugList = this.createNewTrees(res.data)
        }
      })
    },
    fetchMedical() {
      getMedicalListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.medicalList = this.createNewTrees(res.data)
        }
      })
    },
    fetchResearch() {
      getResearchListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.researchList = this.createNewTrees(res.data)
        }
      })
    },
    fetchReagent() {
      getReagentListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.reagentList = this.createNewTrees(res.data)
        }
      })
    },
    fetchInstrument() {
      getInstrumentListArticle({
        ...this.filterParams
      }).then(res => {
        if (res.code === 200) {
          this.instrumentList = this.createNewTrees(res.data)
        }
      })
    },
    fetchData() {
      this.chartLoading = true
      getZhiKuLabelChart({
        ...this.filterParams,
        ...this.chartFilter
      }).then(res => {
        this.chartLoading = false
        // this.chartData = res.data
        console.log(res, 'res chart')
        const list = res.data.data.map(item => item.category)
        this.chartData.categories = Array.from(new Set(list)).map(item => ({
          name: item
        }))
        this.chartData.links = res.data.relation
        // const max = Math.max(...res.data.data.map(e => e.count))
        const sizeMap = {
          0: 64,
          1: 56,
          2: 36
        }
        const nodes = res.data.data.map(item => ({
          ...item,
          value: item.count,
          symbolSize: item.category === '主题生物标志物' ? 84 : sizeMap[item.level]
        }))
        this.chartData.nodes = nodes
      })
    },
    handleMode(mode) {
      this.mode = mode
    },
    subscribeHandle() {
      addZhiKuRetrieval({
        content: '',
        formulas: this.formulas,
        filter: {
          id: this.$route.query.id,
          name: this.$route.query.name,
          disease: this.disease,
          biomarkers: this.biomarkers,
          drug: this.drug,
          medical: this.medical,
          research: this.research,
          reagent: this.reagent,
          instrument: this.instrument
        }
      }).then(res => {
        if (res.code === 200) {
          Message.success({
            content: '订阅成功',
            duration: 2
          })
        }
      })
    },
    handleNodeClick(data) {
      // if (data.key && data.key !== 'biomarker__id') {
      //   this.$set(this.chartFilter, data.key, data.id)
      // }
      const ignoreList = [
        '主题生物标志物',
        '相关疾病',
        '医学方向',
        '研究人群',
        '相关生物标志物',
        '试剂品牌',
        '仪器厂家'
      ]
      const arr = data.id.split('|')
      if (arr.length && !ignoreList.includes(data.name)) {
        this.$set(this.chartFilter, arr[0], arr[1])
      }
    },
    handleLanguageChange(e) {
      this.language = e
    },
    handleLiteratureTypeChange(e) {
      this.literatureType = e
    }
  }
}
</script>

<style lang="less" scoped>
.header-formulas {
  margin-left: 20px;
  display: flex;
  flex-wrap: wrap;
  padding: 5px 20px;
  font-size: 16px;
  color: #656e77;
  background-color: #deebf8;
  border-radius: 15px;
  .formula-item {
    margin-right: 6px;
  }
}
.page-wrap {
  .header-wrap {
    color: #969fa8;
    font-size: 16px;
    padding-top: 24px;
    display: flex;
    align-items: center;

    .title {
      cursor: pointer;
      display: flex;
      align-items: center;

      & > i {
        margin-right: 4px;
      }

      .slash {
        margin: 0 8px;
      }

      .filter-wrap {
        color: #656e77;
        padding: 5px 20px;
        background: rgba(0, 127, 255, 0.08);
        border-radius: 15px;
      }
    }

    .clear {
      width: 116px;
      height: 32px;
      border-radius: 6px;
      border: 1px solid #007fff;
      font-size: 16px;
      display: flex;
      align-items: center;
      justify-content: center;
      color: #007fff;
      cursor: pointer;

      & > i {
        margin-right: 8px;
      }
    }
  }

  .search-wrap {
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 12px;
    display: flex;
    margin-bottom: 24px;
  }

  .chart-tupu {
    padding: 20px 40px;
    background-color: #fff;
    border-radius: 12px;
    margin-bottom: 24px;
    height: 500px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .filter-card {
    padding: 16px 24px 40px 24px;
    background-color: #fff;
    border-radius: 12px;

    .filter-item {
      margin-top: 20px;
      .title {
        font-size: 16px;
        color: #202327;
        margin-bottom: 16px;
      }
    }
  }
}
</style>
